import { getThemedAssetByCategoryAndKey } from '@/utils/assetsRepository';
import { ASSETS_CATEGORY, ILLUSTRATIONS_KEYNAMES } from '@/constants/assetsRepository';

export const bookingConfirmed = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.ILLUSTRATIONS, ILLUSTRATIONS_KEYNAMES.BOOKING_CONFIRMED);
export const bookingCancelled = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.ILLUSTRATIONS, ILLUSTRATIONS_KEYNAMES.BOOKING_CANCELLED);

export const successOk = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.ILLUSTRATIONS, ILLUSTRATIONS_KEYNAMES.OK);
export const successThanks = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.ILLUSTRATIONS, ILLUSTRATIONS_KEYNAMES.THANKS);

export const genericError = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.ILLUSTRATIONS, ILLUSTRATIONS_KEYNAMES.ERROR);
export const genericUserBlock = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.ILLUSTRATIONS, ILLUSTRATIONS_KEYNAMES.BLOCKED);

export const editBookingTimeSuccess = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.ILLUSTRATIONS, ILLUSTRATIONS_KEYNAMES.EDIT_TIME_SUCCESS);
export const editBookingTimeError = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.ILLUSTRATIONS, ILLUSTRATIONS_KEYNAMES.EDIT_TIME_ERROR);

export const protection = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.ILLUSTRATIONS, ILLUSTRATIONS_KEYNAMES.PROTECTION);

export const resetPasswordSuccess = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.ILLUSTRATIONS, ILLUSTRATIONS_KEYNAMES.RESET_PASSWORD_EMAIL);

export const waitingForValidationError = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.ILLUSTRATIONS, ILLUSTRATIONS_KEYNAMES.DOCUMENT_BG);

export const document = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.ILLUSTRATIONS, ILLUSTRATIONS_KEYNAMES.DOCUMENT);

export const emptyCarpoolingTrips = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.ILLUSTRATIONS, ILLUSTRATIONS_KEYNAMES.NO_ACTIVE_BOOKING);

export const emptyActiveBookings = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.ILLUSTRATIONS, ILLUSTRATIONS_KEYNAMES.NO_ACTIVE_BOOKING);

export const emptyPaymentMethods = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.ILLUSTRATIONS, ILLUSTRATIONS_KEYNAMES.CARD);
export const emptyLocation = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.ILLUSTRATIONS, ILLUSTRATIONS_KEYNAMES.LOCATION);
export const tariffExpiredEmptyState = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.ILLUSTRATIONS, ILLUSTRATIONS_KEYNAMES.NO_ACTIVE_BOOKING);
export const ubeeqoToOndemand = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.ILLUSTRATIONS, ILLUSTRATIONS_KEYNAMES.UBEEQO_TO_ONDEMAND);
export const downloadAppImage = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.ILLUSTRATIONS, ILLUSTRATIONS_KEYNAMES.DOWNLOAD_APP);
export const downloadAppImageNoBg = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.ILLUSTRATIONS, ILLUSTRATIONS_KEYNAMES.DOWNLOAD_APP_NO_BG_CENTERED);
